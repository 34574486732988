import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { initializeApp } from '@firebase/app'
import { getAnalytics, setAnalyticsCollectionEnabled } from '@firebase/analytics'
import useCookieConsent from './hooks/useCookieConsent'
import { watch } from 'vue'

import type { Router } from 'vue-router'
import { type Vue } from '@sentry/vue/types/types'

const firebaseConfig = JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG)
initializeApp(firebaseConfig)

const { granted } = useCookieConsent()
// configure analytics
const analytics = getAnalytics()
watch(granted, granted => {
  setAnalyticsCollectionEnabled(analytics, granted ?? false)
}, { immediate: true })

export function initSentry ({ app, router }: { app: Vue, router: Router }) {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      release: `meeting-vote-web@${import.meta.env.VITE_COMMIT_REF?.toString()}`,
      environment: import.meta.env.VITE_CONTEXT?.toString(),
      logErrors: true,
      integrations: [new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['api.agm-vote.com']
      })],
      tracesSampleRate: 1.0
    })
  }
}
