import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import type * as VueCompositionApi from 'vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type Timestamp = number

export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSONObject: { input: any; output: any; }
  Timestamp: { input: Timestamp; output: Timestamp; }
};

export type Actor = User | Voter;

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type Election = {
  __typename?: 'Election';
  admins: Array<User>;
  checkoutSessionIds?: Maybe<Array<Scalars['String']['output']>>;
  closedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  motion?: Maybe<Motion>;
  motions: Array<Motion>;
  name: Scalars['String']['output'];
  openedAt?: Maybe<Scalars['Timestamp']['output']>;
  organiserName?: Maybe<Scalars['String']['output']>;
  paidVoters: Scalars['Int']['output'];
  privateVotes: VotePrivacy;
  status: ElectionStatus;
  viewers: Array<User>;
  voters: Array<Voter>;
};


export type ElectionMotionArgs = {
  motionId: Scalars['ID']['input'];
};


export type ElectionMotionsArgs = {
  status?: InputMaybe<MotionStatus>;
};

export type ElectionCheckout = CheckoutSession | Election;

export type ElectionInput = {
  name: Scalars['String']['input'];
  organiserName: Scalars['String']['input'];
  privateVotes: VotePrivacy;
};

export enum ElectionRole {
  Admin = 'admin',
  Viewer = 'viewer'
}

export enum ElectionStatus {
  Closed = 'closed',
  Open = 'open',
  Scheduled = 'scheduled'
}

export type Email = {
  __typename?: 'Email';
  events: Array<MailEvent>;
  id: Scalars['ID']['output'];
  sentAt: Scalars['Timestamp']['output'];
  status: Scalars['String']['output'];
};

export type MailEvent = {
  __typename?: 'MailEvent';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  errorRelatedTo?: Maybe<Scalars['String']['output']>;
  event: Scalars['String']['output'];
  hardBounce?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  time: Scalars['Timestamp']['output'];
};

export type Motion = {
  __typename?: 'Motion';
  closedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  description: Scalars['String']['output'];
  election: Election;
  excludedVoters: Array<Voter>;
  id: Scalars['ID']['output'];
  isExcluded?: Maybe<Scalars['Boolean']['output']>;
  myVote?: Maybe<Vote>;
  openedAt?: Maybe<Scalars['Timestamp']['output']>;
  order: Scalars['Int']['output'];
  privateVotes: VotePrivacyOverride;
  publishedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: MotionStatus;
  tally: Scalars['JSONObject']['output'];
  votes: Array<Vote>;
};

export type MotionInput = {
  description: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  privateVotes: VotePrivacyOverride;
};

export enum MotionStatus {
  Closed = 'closed',
  Open = 'open',
  Published = 'published',
  Scheduled = 'scheduled'
}

export type Mutation = {
  __typename?: 'Mutation';
  addElectionUser: Election;
  addMotionExcludedVoter: Motion;
  castVote?: Maybe<Vote>;
  changeElectionStatus: ElectionCheckout;
  changeMotionStatus: Motion;
  createElection: Election;
  createElectionCheckoutSession: CheckoutSession;
  createElectionIconUploadUrl: Scalars['String']['output'];
  createElectionVoter: Election;
  createMotion: Motion;
  deleteElectionVoter: Election;
  deleteMotion: Motion;
  removeElectionUser: Election;
  removeMotionExcludedVoter: Motion;
  resendElectionVoterEmail: Voter;
  rollElectionVoterPin: Voter;
  sendElectionVoterEmails: Array<Voter>;
  updateElection: Election;
  updateElectionVoter: Voter;
  updateMotion: Motion;
  updateUser: User;
};


export type MutationAddElectionUserArgs = {
  electionId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  role: ElectionRole;
};


export type MutationAddMotionExcludedVoterArgs = {
  motionId: Scalars['ID']['input'];
  voterId: Scalars['ID']['input'];
};


export type MutationCastVoteArgs = {
  motionId: Scalars['ID']['input'];
  vote: VoteOption;
};


export type MutationChangeElectionStatusArgs = {
  electionId: Scalars['ID']['input'];
  status: ElectionStatus;
};


export type MutationChangeMotionStatusArgs = {
  motionId: Scalars['ID']['input'];
  status: MotionStatus;
};


export type MutationCreateElectionArgs = {
  input: ElectionInput;
};


export type MutationCreateElectionCheckoutSessionArgs = {
  electionId: Scalars['ID']['input'];
};


export type MutationCreateElectionIconUploadUrlArgs = {
  electionId: Scalars['ID']['input'];
};


export type MutationCreateElectionVoterArgs = {
  electionId: Scalars['ID']['input'];
  input: VoterInput;
};


export type MutationCreateMotionArgs = {
  electionId: Scalars['ID']['input'];
  input: MotionInput;
};


export type MutationDeleteElectionVoterArgs = {
  electionId: Scalars['ID']['input'];
  voterId: Scalars['ID']['input'];
};


export type MutationDeleteMotionArgs = {
  motionId: Scalars['ID']['input'];
};


export type MutationRemoveElectionUserArgs = {
  electionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemoveMotionExcludedVoterArgs = {
  motionId: Scalars['ID']['input'];
  voterId: Scalars['ID']['input'];
};


export type MutationResendElectionVoterEmailArgs = {
  electionId: Scalars['ID']['input'];
  voterId: Scalars['ID']['input'];
};


export type MutationRollElectionVoterPinArgs = {
  electionId: Scalars['ID']['input'];
  voterId: Scalars['ID']['input'];
};


export type MutationSendElectionVoterEmailsArgs = {
  electionId: Scalars['ID']['input'];
};


export type MutationUpdateElectionArgs = {
  electionId: Scalars['ID']['input'];
  input: ElectionInput;
};


export type MutationUpdateElectionVoterArgs = {
  electionId: Scalars['ID']['input'];
  input: VoterInput;
  voterId: Scalars['ID']['input'];
};


export type MutationUpdateMotionArgs = {
  input: MotionInput;
  motionId: Scalars['ID']['input'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};

export type Query = {
  __typename?: 'Query';
  election?: Maybe<Election>;
  elections: Array<Election>;
  me?: Maybe<Actor>;
};


export type QueryElectionArgs = {
  electionId: Scalars['ID']['input'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type UserInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Vote = {
  __typename?: 'Vote';
  history: Array<VoteHistoryEntry>;
  id: Scalars['ID']['output'];
  motion: Motion;
  vote?: Maybe<VoteOption>;
  votedAt: Scalars['Timestamp']['output'];
  voter: Voter;
};

export type VoteHistoryEntry = {
  __typename?: 'VoteHistoryEntry';
  id: Scalars['ID']['output'];
  ip?: Maybe<Scalars['String']['output']>;
  vote?: Maybe<VoteOption>;
  votedAt: Scalars['Timestamp']['output'];
};

export enum VoteOption {
  Abstain = 'abstain',
  No = 'no',
  Yes = 'yes'
}

export enum VotePrivacy {
  Private = 'private',
  Public = 'public'
}

export enum VotePrivacyOverride {
  Default = 'default',
  Private = 'private',
  Public = 'public'
}

export type Voter = {
  __typename?: 'Voter';
  email?: Maybe<Scalars['String']['output']>;
  emails: Array<Email>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  secret?: Maybe<Scalars['String']['output']>;
};

export type VoterInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MotionFragment = { __typename?: 'Motion', id: string, description: string, order: number, status: MotionStatus, createdAt: Timestamp, openedAt?: Timestamp | null, closedAt?: Timestamp | null, publishedAt?: Timestamp | null, privateVotes: VotePrivacyOverride, tally: any, votes: Array<{ __typename?: 'Vote', id: string, vote?: VoteOption | null, votedAt: Timestamp, voter: { __typename?: 'Voter', id: string }, history: Array<{ __typename?: 'VoteHistoryEntry', id: string, vote?: VoteOption | null, votedAt: Timestamp, ip?: string | null }> }>, excludedVoters: Array<{ __typename?: 'Voter', id: string, name?: string | null }> };

export type VoterMotionFragment = { __typename?: 'Motion', id: string, order: number, description: string, status: MotionStatus, openedAt?: Timestamp | null, publishedAt?: Timestamp | null, privateVotes: VotePrivacyOverride, isExcluded?: boolean | null, tally: any, myVote?: { __typename?: 'Vote', id: string, vote?: VoteOption | null, votedAt: Timestamp } | null };

export type AddElectionUserMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  role: ElectionRole;
}>;


export type AddElectionUserMutation = { __typename?: 'Mutation', addElectionUser: { __typename?: 'Election', id: string, admins: Array<{ __typename?: 'User', id: string, name?: string | null, email: string }>, viewers: Array<{ __typename?: 'User', id: string, name?: string | null, email: string }> } };

export type AddMotionExcludedVoterMutationVariables = Exact<{
  motionId: Scalars['ID']['input'];
  voterId: Scalars['ID']['input'];
}>;


export type AddMotionExcludedVoterMutation = { __typename?: 'Mutation', addMotionExcludedVoter: { __typename?: 'Motion', id: string, description: string, order: number, status: MotionStatus, createdAt: Timestamp, openedAt?: Timestamp | null, closedAt?: Timestamp | null, publishedAt?: Timestamp | null, privateVotes: VotePrivacyOverride, tally: any, votes: Array<{ __typename?: 'Vote', id: string, vote?: VoteOption | null, votedAt: Timestamp, voter: { __typename?: 'Voter', id: string }, history: Array<{ __typename?: 'VoteHistoryEntry', id: string, vote?: VoteOption | null, votedAt: Timestamp, ip?: string | null }> }>, excludedVoters: Array<{ __typename?: 'Voter', id: string, name?: string | null }> } };

export type CastVotesMutationVariables = Exact<{
  motionId: Scalars['ID']['input'];
  vote: VoteOption;
}>;


export type CastVotesMutation = { __typename?: 'Mutation', castVote?: { __typename?: 'Vote', id: string, vote?: VoteOption | null, votedAt: Timestamp } | null };

export type ChangeElectionStatusMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
  status: ElectionStatus;
}>;


export type ChangeElectionStatusMutation = { __typename?: 'Mutation', changeElectionStatus: { __typename?: 'CheckoutSession', id: string, url: string } | { __typename?: 'Election', id: string, status: ElectionStatus } };

export type ChangeMotionStatusMutationVariables = Exact<{
  motionId: Scalars['ID']['input'];
  status: MotionStatus;
}>;


export type ChangeMotionStatusMutation = { __typename?: 'Mutation', changeMotionStatus: { __typename?: 'Motion', id: string, description: string, order: number, status: MotionStatus, createdAt: Timestamp, openedAt?: Timestamp | null, closedAt?: Timestamp | null, publishedAt?: Timestamp | null, privateVotes: VotePrivacyOverride, tally: any, votes: Array<{ __typename?: 'Vote', id: string, vote?: VoteOption | null, votedAt: Timestamp, voter: { __typename?: 'Voter', id: string }, history: Array<{ __typename?: 'VoteHistoryEntry', id: string, vote?: VoteOption | null, votedAt: Timestamp, ip?: string | null }> }>, excludedVoters: Array<{ __typename?: 'Voter', id: string, name?: string | null }> } };

export type CreateElectionMutationVariables = Exact<{
  input: ElectionInput;
}>;


export type CreateElectionMutation = { __typename?: 'Mutation', createElection: { __typename?: 'Election', id: string, name: string, organiserName?: string | null, createdAt: Timestamp, status: ElectionStatus } };

export type CreateElectionCheckoutSessionMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
}>;


export type CreateElectionCheckoutSessionMutation = { __typename?: 'Mutation', createElectionCheckoutSession: { __typename?: 'CheckoutSession', id: string, url: string } };

export type CreateElectionIconUploadUrlMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
}>;


export type CreateElectionIconUploadUrlMutation = { __typename?: 'Mutation', createElectionIconUploadUrl: string };

export type CreateElectionVoterMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
  input: VoterInput;
}>;


export type CreateElectionVoterMutation = { __typename?: 'Mutation', createElectionVoter: { __typename?: 'Election', id: string, voters: Array<{ __typename?: 'Voter', id: string, name?: string | null, secret?: string | null }> } };

export type CreateMotionMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
  input: MotionInput;
}>;


export type CreateMotionMutation = { __typename?: 'Mutation', createMotion: { __typename?: 'Motion', id: string, description: string, order: number, status: MotionStatus, createdAt: Timestamp, openedAt?: Timestamp | null, closedAt?: Timestamp | null, publishedAt?: Timestamp | null, privateVotes: VotePrivacyOverride, tally: any, votes: Array<{ __typename?: 'Vote', id: string, vote?: VoteOption | null, votedAt: Timestamp, voter: { __typename?: 'Voter', id: string }, history: Array<{ __typename?: 'VoteHistoryEntry', id: string, vote?: VoteOption | null, votedAt: Timestamp, ip?: string | null }> }>, excludedVoters: Array<{ __typename?: 'Voter', id: string, name?: string | null }> } };

export type DeleteElectionVoterMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
  voterId: Scalars['ID']['input'];
}>;


export type DeleteElectionVoterMutation = { __typename?: 'Mutation', deleteElectionVoter: { __typename?: 'Election', id: string, voters: Array<{ __typename?: 'Voter', id: string, name?: string | null, secret?: string | null }> } };

export type DeleteMotionMutationVariables = Exact<{
  motionId: Scalars['ID']['input'];
}>;


export type DeleteMotionMutation = { __typename?: 'Mutation', deleteMotion: { __typename?: 'Motion', id: string } };

export type RemoveElectionUserMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type RemoveElectionUserMutation = { __typename?: 'Mutation', removeElectionUser: { __typename?: 'Election', id: string, admins: Array<{ __typename?: 'User', id: string, email: string, name?: string | null }>, viewers: Array<{ __typename?: 'User', id: string, email: string, name?: string | null }> } };

export type RemoveMotionExcludedVoterMutationVariables = Exact<{
  motionId: Scalars['ID']['input'];
  voterId: Scalars['ID']['input'];
}>;


export type RemoveMotionExcludedVoterMutation = { __typename?: 'Mutation', removeMotionExcludedVoter: { __typename?: 'Motion', id: string, description: string, order: number, status: MotionStatus, createdAt: Timestamp, openedAt?: Timestamp | null, closedAt?: Timestamp | null, publishedAt?: Timestamp | null, privateVotes: VotePrivacyOverride, tally: any, votes: Array<{ __typename?: 'Vote', id: string, vote?: VoteOption | null, votedAt: Timestamp, voter: { __typename?: 'Voter', id: string }, history: Array<{ __typename?: 'VoteHistoryEntry', id: string, vote?: VoteOption | null, votedAt: Timestamp, ip?: string | null }> }>, excludedVoters: Array<{ __typename?: 'Voter', id: string, name?: string | null }> } };

export type ResendElectionVoterEmailsMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
  voterId: Scalars['ID']['input'];
}>;


export type ResendElectionVoterEmailsMutation = { __typename?: 'Mutation', resendElectionVoterEmail: { __typename?: 'Voter', id: string } };

export type RollElectionVoterPinMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
  voterId: Scalars['ID']['input'];
}>;


export type RollElectionVoterPinMutation = { __typename?: 'Mutation', rollElectionVoterPin: { __typename?: 'Voter', id: string, name?: string | null, secret?: string | null } };

export type SendElectionVoterEmailsMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
}>;


export type SendElectionVoterEmailsMutation = { __typename?: 'Mutation', sendElectionVoterEmails: Array<{ __typename?: 'Voter', id: string }> };

export type UpdateElectionMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
  input: ElectionInput;
}>;


export type UpdateElectionMutation = { __typename?: 'Mutation', updateElection: { __typename?: 'Election', id: string, name: string, organiserName?: string | null, privateVotes: VotePrivacy } };

export type UpdateElectionVoterMutationVariables = Exact<{
  electionId: Scalars['ID']['input'];
  voterId: Scalars['ID']['input'];
  input: VoterInput;
}>;


export type UpdateElectionVoterMutation = { __typename?: 'Mutation', updateElectionVoter: { __typename?: 'Voter', id: string, name?: string | null, email?: string | null } };

export type UpdateMotionMutationVariables = Exact<{
  motionId: Scalars['ID']['input'];
  input: MotionInput;
}>;


export type UpdateMotionMutation = { __typename?: 'Mutation', updateMotion: { __typename?: 'Motion', id: string, description: string, order: number, status: MotionStatus, createdAt: Timestamp, openedAt?: Timestamp | null, closedAt?: Timestamp | null, publishedAt?: Timestamp | null, privateVotes: VotePrivacyOverride, tally: any, votes: Array<{ __typename?: 'Vote', id: string, vote?: VoteOption | null, votedAt: Timestamp, voter: { __typename?: 'Voter', id: string }, history: Array<{ __typename?: 'VoteHistoryEntry', id: string, vote?: VoteOption | null, votedAt: Timestamp, ip?: string | null }> }>, excludedVoters: Array<{ __typename?: 'Voter', id: string, name?: string | null }> } };

export type ElectionQueryVariables = Exact<{
  electionId: Scalars['ID']['input'];
}>;


export type ElectionQuery = { __typename?: 'Query', election?: { __typename?: 'Election', id: string, name: string, organiserName?: string | null, status: ElectionStatus, icon?: string | null, createdAt: Timestamp, openedAt?: Timestamp | null, closedAt?: Timestamp | null, paidVoters: number, privateVotes: VotePrivacy, admins: Array<{ __typename?: 'User', id: string, email: string, name?: string | null }>, viewers: Array<{ __typename?: 'User', id: string, email: string, name?: string | null }>, voters: Array<{ __typename?: 'Voter', id: string, name?: string | null, email?: string | null, secret?: string | null, emails: Array<{ __typename?: 'Email', id: string, status: string, sentAt: Timestamp, events: Array<{ __typename?: 'MailEvent', id: string, event: string, time: Timestamp, blocked?: boolean | null, hardBounce?: boolean | null, errorRelatedTo?: string | null, error?: string | null }> }> }>, motions: Array<{ __typename?: 'Motion', id: string, description: string, order: number, status: MotionStatus, createdAt: Timestamp, openedAt?: Timestamp | null, closedAt?: Timestamp | null, publishedAt?: Timestamp | null, privateVotes: VotePrivacyOverride, tally: any, votes: Array<{ __typename?: 'Vote', id: string, vote?: VoteOption | null, votedAt: Timestamp, voter: { __typename?: 'Voter', id: string }, history: Array<{ __typename?: 'VoteHistoryEntry', id: string, vote?: VoteOption | null, votedAt: Timestamp, ip?: string | null }> }>, excludedVoters: Array<{ __typename?: 'Voter', id: string, name?: string | null }> }> } | null };

export type PaymentStatusQueryVariables = Exact<{
  electionId: Scalars['ID']['input'];
}>;


export type PaymentStatusQuery = { __typename?: 'Query', election?: { __typename?: 'Election', id: string, status: ElectionStatus, paidVoters: number, checkoutSessionIds?: Array<string> | null } | null };

export type ElectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ElectionsQuery = { __typename?: 'Query', elections: Array<{ __typename?: 'Election', id: string, name: string, status: ElectionStatus, createdAt: Timestamp }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, name?: string | null, email: string, emailVerified: boolean } | { __typename?: 'Voter', id: string, name?: string | null } | null };

export type MotionQueryVariables = Exact<{
  electionId: Scalars['ID']['input'];
  motionId: Scalars['ID']['input'];
}>;


export type MotionQuery = { __typename?: 'Query', election?: { __typename?: 'Election', id: string, motion?: { __typename?: 'Motion', id: string, description: string, order: number, status: MotionStatus, createdAt: Timestamp, openedAt?: Timestamp | null, closedAt?: Timestamp | null, publishedAt?: Timestamp | null, privateVotes: VotePrivacyOverride, tally: any, votes: Array<{ __typename?: 'Vote', id: string, vote?: VoteOption | null, votedAt: Timestamp, voter: { __typename?: 'Voter', id: string }, history: Array<{ __typename?: 'VoteHistoryEntry', id: string, vote?: VoteOption | null, votedAt: Timestamp, ip?: string | null }> }>, excludedVoters: Array<{ __typename?: 'Voter', id: string, name?: string | null }> } | null } | null };

export type VoterElectionQueryVariables = Exact<{
  electionId: Scalars['ID']['input'];
  motionStatus?: InputMaybe<MotionStatus>;
  withIcon: Scalars['Boolean']['input'];
}>;


export type VoterElectionQuery = { __typename?: 'Query', election?: { __typename?: 'Election', id: string, name: string, status: ElectionStatus, icon?: string | null, openedAt?: Timestamp | null, privateVotes: VotePrivacy, motions: Array<{ __typename?: 'Motion', id: string, order: number, description: string, status: MotionStatus, openedAt?: Timestamp | null, publishedAt?: Timestamp | null, privateVotes: VotePrivacyOverride, isExcluded?: boolean | null, tally: any, myVote?: { __typename?: 'Vote', id: string, vote?: VoteOption | null, votedAt: Timestamp } | null }> } | null };

export type ElectionFragment = { __typename?: 'Election', id: string, motions: Array<{ __typename?: 'Motion', id: string, description: string, order: number, status: MotionStatus, createdAt: Timestamp, openedAt?: Timestamp | null, closedAt?: Timestamp | null, publishedAt?: Timestamp | null, privateVotes: VotePrivacyOverride, tally: any, votes: Array<{ __typename?: 'Vote', id: string, vote?: VoteOption | null, votedAt: Timestamp, voter: { __typename?: 'Voter', id: string }, history: Array<{ __typename?: 'VoteHistoryEntry', id: string, vote?: VoteOption | null, votedAt: Timestamp, ip?: string | null }> }>, excludedVoters: Array<{ __typename?: 'Voter', id: string, name?: string | null }> }> };

export const VoterMotionFragmentDoc = gql`
    fragment VoterMotionFragment on Motion {
  id
  order
  description
  status
  openedAt
  publishedAt
  privateVotes
  isExcluded
  myVote {
    id
    vote
    votedAt
  }
  tally
}
    `;
export const MotionFragmentDoc = gql`
    fragment MotionFragment on Motion {
  id
  description
  order
  status
  createdAt
  openedAt
  closedAt
  publishedAt
  privateVotes
  tally
  votes {
    id
    vote
    votedAt
    voter {
      id
    }
    history {
      id
      vote
      votedAt
      ip
    }
  }
  excludedVoters {
    id
    name
  }
}
    `;
export const ElectionFragmentDoc = gql`
    fragment ElectionFragment on Election {
  id
  motions {
    ...MotionFragment
  }
}
    ${MotionFragmentDoc}`;
export const AddElectionUserDocument = gql`
    mutation AddElectionUser($electionId: ID!, $email: String!, $role: ElectionRole!) {
  addElectionUser(electionId: $electionId, email: $email, role: $role) {
    id
    admins {
      id
      name
      email
    }
    viewers {
      id
      name
      email
    }
  }
}
    `;

/**
 * __useAddElectionUserMutation__
 *
 * To run a mutation, you first call `useAddElectionUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddElectionUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddElectionUserMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *     email: // value for 'email'
 *     role: // value for 'role'
 *   },
 * });
 */
export function useAddElectionUserMutation(options: VueApolloComposable.UseMutationOptions<AddElectionUserMutation, AddElectionUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AddElectionUserMutation, AddElectionUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<AddElectionUserMutation, AddElectionUserMutationVariables>(AddElectionUserDocument, options);
}
export type AddElectionUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AddElectionUserMutation, AddElectionUserMutationVariables>;
export const AddMotionExcludedVoterDocument = gql`
    mutation AddMotionExcludedVoter($motionId: ID!, $voterId: ID!) {
  addMotionExcludedVoter(motionId: $motionId, voterId: $voterId) {
    ...MotionFragment
  }
}
    ${MotionFragmentDoc}`;

/**
 * __useAddMotionExcludedVoterMutation__
 *
 * To run a mutation, you first call `useAddMotionExcludedVoterMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddMotionExcludedVoterMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddMotionExcludedVoterMutation({
 *   variables: {
 *     motionId: // value for 'motionId'
 *     voterId: // value for 'voterId'
 *   },
 * });
 */
export function useAddMotionExcludedVoterMutation(options: VueApolloComposable.UseMutationOptions<AddMotionExcludedVoterMutation, AddMotionExcludedVoterMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AddMotionExcludedVoterMutation, AddMotionExcludedVoterMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<AddMotionExcludedVoterMutation, AddMotionExcludedVoterMutationVariables>(AddMotionExcludedVoterDocument, options);
}
export type AddMotionExcludedVoterMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AddMotionExcludedVoterMutation, AddMotionExcludedVoterMutationVariables>;
export const CastVotesDocument = gql`
    mutation CastVotes($motionId: ID!, $vote: VoteOption!) {
  castVote(motionId: $motionId, vote: $vote) {
    id
    vote
    votedAt
  }
}
    `;

/**
 * __useCastVotesMutation__
 *
 * To run a mutation, you first call `useCastVotesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCastVotesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCastVotesMutation({
 *   variables: {
 *     motionId: // value for 'motionId'
 *     vote: // value for 'vote'
 *   },
 * });
 */
export function useCastVotesMutation(options: VueApolloComposable.UseMutationOptions<CastVotesMutation, CastVotesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CastVotesMutation, CastVotesMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CastVotesMutation, CastVotesMutationVariables>(CastVotesDocument, options);
}
export type CastVotesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CastVotesMutation, CastVotesMutationVariables>;
export const ChangeElectionStatusDocument = gql`
    mutation ChangeElectionStatus($electionId: ID!, $status: ElectionStatus!) {
  changeElectionStatus(electionId: $electionId, status: $status) {
    ... on Election {
      id
      status
    }
    ... on CheckoutSession {
      id
      url
    }
  }
}
    `;

/**
 * __useChangeElectionStatusMutation__
 *
 * To run a mutation, you first call `useChangeElectionStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useChangeElectionStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useChangeElectionStatusMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *     status: // value for 'status'
 *   },
 * });
 */
export function useChangeElectionStatusMutation(options: VueApolloComposable.UseMutationOptions<ChangeElectionStatusMutation, ChangeElectionStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ChangeElectionStatusMutation, ChangeElectionStatusMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ChangeElectionStatusMutation, ChangeElectionStatusMutationVariables>(ChangeElectionStatusDocument, options);
}
export type ChangeElectionStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ChangeElectionStatusMutation, ChangeElectionStatusMutationVariables>;
export const ChangeMotionStatusDocument = gql`
    mutation ChangeMotionStatus($motionId: ID!, $status: MotionStatus!) {
  changeMotionStatus(motionId: $motionId, status: $status) {
    ...MotionFragment
  }
}
    ${MotionFragmentDoc}`;

/**
 * __useChangeMotionStatusMutation__
 *
 * To run a mutation, you first call `useChangeMotionStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useChangeMotionStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useChangeMotionStatusMutation({
 *   variables: {
 *     motionId: // value for 'motionId'
 *     status: // value for 'status'
 *   },
 * });
 */
export function useChangeMotionStatusMutation(options: VueApolloComposable.UseMutationOptions<ChangeMotionStatusMutation, ChangeMotionStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ChangeMotionStatusMutation, ChangeMotionStatusMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ChangeMotionStatusMutation, ChangeMotionStatusMutationVariables>(ChangeMotionStatusDocument, options);
}
export type ChangeMotionStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ChangeMotionStatusMutation, ChangeMotionStatusMutationVariables>;
export const CreateElectionDocument = gql`
    mutation CreateElection($input: ElectionInput!) {
  createElection(input: $input) {
    id
    name
    organiserName
    createdAt
    status
  }
}
    `;

/**
 * __useCreateElectionMutation__
 *
 * To run a mutation, you first call `useCreateElectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateElectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateElectionMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateElectionMutation(options: VueApolloComposable.UseMutationOptions<CreateElectionMutation, CreateElectionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateElectionMutation, CreateElectionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateElectionMutation, CreateElectionMutationVariables>(CreateElectionDocument, options);
}
export type CreateElectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateElectionMutation, CreateElectionMutationVariables>;
export const CreateElectionCheckoutSessionDocument = gql`
    mutation CreateElectionCheckoutSession($electionId: ID!) {
  createElectionCheckoutSession(electionId: $electionId) {
    id
    url
  }
}
    `;

/**
 * __useCreateElectionCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateElectionCheckoutSessionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateElectionCheckoutSessionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateElectionCheckoutSessionMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *   },
 * });
 */
export function useCreateElectionCheckoutSessionMutation(options: VueApolloComposable.UseMutationOptions<CreateElectionCheckoutSessionMutation, CreateElectionCheckoutSessionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateElectionCheckoutSessionMutation, CreateElectionCheckoutSessionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateElectionCheckoutSessionMutation, CreateElectionCheckoutSessionMutationVariables>(CreateElectionCheckoutSessionDocument, options);
}
export type CreateElectionCheckoutSessionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateElectionCheckoutSessionMutation, CreateElectionCheckoutSessionMutationVariables>;
export const CreateElectionIconUploadUrlDocument = gql`
    mutation CreateElectionIconUploadUrl($electionId: ID!) {
  createElectionIconUploadUrl(electionId: $electionId)
}
    `;

/**
 * __useCreateElectionIconUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateElectionIconUploadUrlMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateElectionIconUploadUrlMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateElectionIconUploadUrlMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *   },
 * });
 */
export function useCreateElectionIconUploadUrlMutation(options: VueApolloComposable.UseMutationOptions<CreateElectionIconUploadUrlMutation, CreateElectionIconUploadUrlMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateElectionIconUploadUrlMutation, CreateElectionIconUploadUrlMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateElectionIconUploadUrlMutation, CreateElectionIconUploadUrlMutationVariables>(CreateElectionIconUploadUrlDocument, options);
}
export type CreateElectionIconUploadUrlMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateElectionIconUploadUrlMutation, CreateElectionIconUploadUrlMutationVariables>;
export const CreateElectionVoterDocument = gql`
    mutation createElectionVoter($electionId: ID!, $input: VoterInput!) {
  createElectionVoter(electionId: $electionId, input: $input) {
    id
    voters {
      id
      name
      secret
    }
  }
}
    `;

/**
 * __useCreateElectionVoterMutation__
 *
 * To run a mutation, you first call `useCreateElectionVoterMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateElectionVoterMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateElectionVoterMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateElectionVoterMutation(options: VueApolloComposable.UseMutationOptions<CreateElectionVoterMutation, CreateElectionVoterMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateElectionVoterMutation, CreateElectionVoterMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateElectionVoterMutation, CreateElectionVoterMutationVariables>(CreateElectionVoterDocument, options);
}
export type CreateElectionVoterMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateElectionVoterMutation, CreateElectionVoterMutationVariables>;
export const CreateMotionDocument = gql`
    mutation CreateMotion($electionId: ID!, $input: MotionInput!) {
  createMotion(electionId: $electionId, input: $input) {
    ...MotionFragment
  }
}
    ${MotionFragmentDoc}`;

/**
 * __useCreateMotionMutation__
 *
 * To run a mutation, you first call `useCreateMotionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateMotionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateMotionMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateMotionMutation(options: VueApolloComposable.UseMutationOptions<CreateMotionMutation, CreateMotionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateMotionMutation, CreateMotionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateMotionMutation, CreateMotionMutationVariables>(CreateMotionDocument, options);
}
export type CreateMotionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateMotionMutation, CreateMotionMutationVariables>;
export const DeleteElectionVoterDocument = gql`
    mutation deleteElectionVoter($electionId: ID!, $voterId: ID!) {
  deleteElectionVoter(electionId: $electionId, voterId: $voterId) {
    id
    voters {
      id
      name
      secret
    }
  }
}
    `;

/**
 * __useDeleteElectionVoterMutation__
 *
 * To run a mutation, you first call `useDeleteElectionVoterMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteElectionVoterMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteElectionVoterMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *     voterId: // value for 'voterId'
 *   },
 * });
 */
export function useDeleteElectionVoterMutation(options: VueApolloComposable.UseMutationOptions<DeleteElectionVoterMutation, DeleteElectionVoterMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteElectionVoterMutation, DeleteElectionVoterMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteElectionVoterMutation, DeleteElectionVoterMutationVariables>(DeleteElectionVoterDocument, options);
}
export type DeleteElectionVoterMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteElectionVoterMutation, DeleteElectionVoterMutationVariables>;
export const DeleteMotionDocument = gql`
    mutation DeleteMotion($motionId: ID!) {
  deleteMotion(motionId: $motionId) {
    id
  }
}
    `;

/**
 * __useDeleteMotionMutation__
 *
 * To run a mutation, you first call `useDeleteMotionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMotionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteMotionMutation({
 *   variables: {
 *     motionId: // value for 'motionId'
 *   },
 * });
 */
export function useDeleteMotionMutation(options: VueApolloComposable.UseMutationOptions<DeleteMotionMutation, DeleteMotionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteMotionMutation, DeleteMotionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteMotionMutation, DeleteMotionMutationVariables>(DeleteMotionDocument, options);
}
export type DeleteMotionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteMotionMutation, DeleteMotionMutationVariables>;
export const RemoveElectionUserDocument = gql`
    mutation RemoveElectionUser($electionId: ID!, $userId: ID!) {
  removeElectionUser(electionId: $electionId, userId: $userId) {
    id
    admins {
      id
      email
      name
    }
    viewers {
      id
      email
      name
    }
  }
}
    `;

/**
 * __useRemoveElectionUserMutation__
 *
 * To run a mutation, you first call `useRemoveElectionUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveElectionUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveElectionUserMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *     userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveElectionUserMutation(options: VueApolloComposable.UseMutationOptions<RemoveElectionUserMutation, RemoveElectionUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RemoveElectionUserMutation, RemoveElectionUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RemoveElectionUserMutation, RemoveElectionUserMutationVariables>(RemoveElectionUserDocument, options);
}
export type RemoveElectionUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RemoveElectionUserMutation, RemoveElectionUserMutationVariables>;
export const RemoveMotionExcludedVoterDocument = gql`
    mutation RemoveMotionExcludedVoter($motionId: ID!, $voterId: ID!) {
  removeMotionExcludedVoter(motionId: $motionId, voterId: $voterId) {
    ...MotionFragment
  }
}
    ${MotionFragmentDoc}`;

/**
 * __useRemoveMotionExcludedVoterMutation__
 *
 * To run a mutation, you first call `useRemoveMotionExcludedVoterMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMotionExcludedVoterMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveMotionExcludedVoterMutation({
 *   variables: {
 *     motionId: // value for 'motionId'
 *     voterId: // value for 'voterId'
 *   },
 * });
 */
export function useRemoveMotionExcludedVoterMutation(options: VueApolloComposable.UseMutationOptions<RemoveMotionExcludedVoterMutation, RemoveMotionExcludedVoterMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RemoveMotionExcludedVoterMutation, RemoveMotionExcludedVoterMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RemoveMotionExcludedVoterMutation, RemoveMotionExcludedVoterMutationVariables>(RemoveMotionExcludedVoterDocument, options);
}
export type RemoveMotionExcludedVoterMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RemoveMotionExcludedVoterMutation, RemoveMotionExcludedVoterMutationVariables>;
export const ResendElectionVoterEmailsDocument = gql`
    mutation ResendElectionVoterEmails($electionId: ID!, $voterId: ID!) {
  resendElectionVoterEmail(electionId: $electionId, voterId: $voterId) {
    id
  }
}
    `;

/**
 * __useResendElectionVoterEmailsMutation__
 *
 * To run a mutation, you first call `useResendElectionVoterEmailsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useResendElectionVoterEmailsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useResendElectionVoterEmailsMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *     voterId: // value for 'voterId'
 *   },
 * });
 */
export function useResendElectionVoterEmailsMutation(options: VueApolloComposable.UseMutationOptions<ResendElectionVoterEmailsMutation, ResendElectionVoterEmailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ResendElectionVoterEmailsMutation, ResendElectionVoterEmailsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ResendElectionVoterEmailsMutation, ResendElectionVoterEmailsMutationVariables>(ResendElectionVoterEmailsDocument, options);
}
export type ResendElectionVoterEmailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ResendElectionVoterEmailsMutation, ResendElectionVoterEmailsMutationVariables>;
export const RollElectionVoterPinDocument = gql`
    mutation RollElectionVoterPin($electionId: ID!, $voterId: ID!) {
  rollElectionVoterPin(electionId: $electionId, voterId: $voterId) {
    id
    name
    secret
  }
}
    `;

/**
 * __useRollElectionVoterPinMutation__
 *
 * To run a mutation, you first call `useRollElectionVoterPinMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRollElectionVoterPinMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRollElectionVoterPinMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *     voterId: // value for 'voterId'
 *   },
 * });
 */
export function useRollElectionVoterPinMutation(options: VueApolloComposable.UseMutationOptions<RollElectionVoterPinMutation, RollElectionVoterPinMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RollElectionVoterPinMutation, RollElectionVoterPinMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RollElectionVoterPinMutation, RollElectionVoterPinMutationVariables>(RollElectionVoterPinDocument, options);
}
export type RollElectionVoterPinMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RollElectionVoterPinMutation, RollElectionVoterPinMutationVariables>;
export const SendElectionVoterEmailsDocument = gql`
    mutation SendElectionVoterEmails($electionId: ID!) {
  sendElectionVoterEmails(electionId: $electionId) {
    id
  }
}
    `;

/**
 * __useSendElectionVoterEmailsMutation__
 *
 * To run a mutation, you first call `useSendElectionVoterEmailsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSendElectionVoterEmailsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSendElectionVoterEmailsMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *   },
 * });
 */
export function useSendElectionVoterEmailsMutation(options: VueApolloComposable.UseMutationOptions<SendElectionVoterEmailsMutation, SendElectionVoterEmailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SendElectionVoterEmailsMutation, SendElectionVoterEmailsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SendElectionVoterEmailsMutation, SendElectionVoterEmailsMutationVariables>(SendElectionVoterEmailsDocument, options);
}
export type SendElectionVoterEmailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SendElectionVoterEmailsMutation, SendElectionVoterEmailsMutationVariables>;
export const UpdateElectionDocument = gql`
    mutation UpdateElection($electionId: ID!, $input: ElectionInput!) {
  updateElection(electionId: $electionId, input: $input) {
    id
    name
    organiserName
    privateVotes
  }
}
    `;

/**
 * __useUpdateElectionMutation__
 *
 * To run a mutation, you first call `useUpdateElectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateElectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateElectionMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateElectionMutation(options: VueApolloComposable.UseMutationOptions<UpdateElectionMutation, UpdateElectionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateElectionMutation, UpdateElectionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateElectionMutation, UpdateElectionMutationVariables>(UpdateElectionDocument, options);
}
export type UpdateElectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateElectionMutation, UpdateElectionMutationVariables>;
export const UpdateElectionVoterDocument = gql`
    mutation UpdateElectionVoter($electionId: ID!, $voterId: ID!, $input: VoterInput!) {
  updateElectionVoter(electionId: $electionId, voterId: $voterId, input: $input) {
    id
    name
    email
  }
}
    `;

/**
 * __useUpdateElectionVoterMutation__
 *
 * To run a mutation, you first call `useUpdateElectionVoterMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateElectionVoterMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateElectionVoterMutation({
 *   variables: {
 *     electionId: // value for 'electionId'
 *     voterId: // value for 'voterId'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateElectionVoterMutation(options: VueApolloComposable.UseMutationOptions<UpdateElectionVoterMutation, UpdateElectionVoterMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateElectionVoterMutation, UpdateElectionVoterMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateElectionVoterMutation, UpdateElectionVoterMutationVariables>(UpdateElectionVoterDocument, options);
}
export type UpdateElectionVoterMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateElectionVoterMutation, UpdateElectionVoterMutationVariables>;
export const UpdateMotionDocument = gql`
    mutation UpdateMotion($motionId: ID!, $input: MotionInput!) {
  updateMotion(motionId: $motionId, input: $input) {
    ...MotionFragment
  }
}
    ${MotionFragmentDoc}`;

/**
 * __useUpdateMotionMutation__
 *
 * To run a mutation, you first call `useUpdateMotionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMotionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateMotionMutation({
 *   variables: {
 *     motionId: // value for 'motionId'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMotionMutation(options: VueApolloComposable.UseMutationOptions<UpdateMotionMutation, UpdateMotionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateMotionMutation, UpdateMotionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateMotionMutation, UpdateMotionMutationVariables>(UpdateMotionDocument, options);
}
export type UpdateMotionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateMotionMutation, UpdateMotionMutationVariables>;
export const ElectionDocument = gql`
    query Election($electionId: ID!) {
  election(electionId: $electionId) {
    id
    name
    organiserName
    status
    icon
    createdAt
    openedAt
    closedAt
    paidVoters
    privateVotes
    admins {
      id
      email
      name
    }
    viewers {
      id
      email
      name
    }
    voters {
      id
      name
      email
      secret
      emails {
        id
        status
        sentAt
        events {
          id
          event
          time
          blocked
          hardBounce
          errorRelatedTo
          error
        }
      }
    }
    motions {
      ...MotionFragment
    }
  }
}
    ${MotionFragmentDoc}`;

/**
 * __useElectionQuery__
 *
 * To run a query within a Vue component, call `useElectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useElectionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useElectionQuery({
 *   electionId: // value for 'electionId'
 * });
 */
export function useElectionQuery(variables: ElectionQueryVariables | VueCompositionApi.Ref<ElectionQueryVariables> | ReactiveFunction<ElectionQueryVariables>, options: VueApolloComposable.UseQueryOptions<ElectionQuery, ElectionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ElectionQuery, ElectionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ElectionQuery, ElectionQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ElectionQuery, ElectionQueryVariables>(ElectionDocument, variables, options);
}
export function useElectionLazyQuery(variables: ElectionQueryVariables | VueCompositionApi.Ref<ElectionQueryVariables> | ReactiveFunction<ElectionQueryVariables>, options: VueApolloComposable.UseQueryOptions<ElectionQuery, ElectionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ElectionQuery, ElectionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ElectionQuery, ElectionQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ElectionQuery, ElectionQueryVariables>(ElectionDocument, variables, options);
}
export type ElectionQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ElectionQuery, ElectionQueryVariables>;
export const PaymentStatusDocument = gql`
    query PaymentStatus($electionId: ID!) {
  election(electionId: $electionId) {
    id
    status
    paidVoters
    checkoutSessionIds
  }
}
    `;

/**
 * __usePaymentStatusQuery__
 *
 * To run a query within a Vue component, call `usePaymentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentStatusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePaymentStatusQuery({
 *   electionId: // value for 'electionId'
 * });
 */
export function usePaymentStatusQuery(variables: PaymentStatusQueryVariables | VueCompositionApi.Ref<PaymentStatusQueryVariables> | ReactiveFunction<PaymentStatusQueryVariables>, options: VueApolloComposable.UseQueryOptions<PaymentStatusQuery, PaymentStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PaymentStatusQuery, PaymentStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PaymentStatusQuery, PaymentStatusQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PaymentStatusQuery, PaymentStatusQueryVariables>(PaymentStatusDocument, variables, options);
}
export function usePaymentStatusLazyQuery(variables: PaymentStatusQueryVariables | VueCompositionApi.Ref<PaymentStatusQueryVariables> | ReactiveFunction<PaymentStatusQueryVariables>, options: VueApolloComposable.UseQueryOptions<PaymentStatusQuery, PaymentStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PaymentStatusQuery, PaymentStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PaymentStatusQuery, PaymentStatusQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PaymentStatusQuery, PaymentStatusQueryVariables>(PaymentStatusDocument, variables, options);
}
export type PaymentStatusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PaymentStatusQuery, PaymentStatusQueryVariables>;
export const ElectionsDocument = gql`
    query Elections {
  elections {
    id
    name
    status
    createdAt
  }
}
    `;

/**
 * __useElectionsQuery__
 *
 * To run a query within a Vue component, call `useElectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useElectionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useElectionsQuery();
 */
export function useElectionsQuery(options: VueApolloComposable.UseQueryOptions<ElectionsQuery, ElectionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ElectionsQuery, ElectionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ElectionsQuery, ElectionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ElectionsQuery, ElectionsQueryVariables>(ElectionsDocument, {}, options);
}
export function useElectionsLazyQuery(options: VueApolloComposable.UseQueryOptions<ElectionsQuery, ElectionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ElectionsQuery, ElectionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ElectionsQuery, ElectionsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ElectionsQuery, ElectionsQueryVariables>(ElectionsDocument, {}, options);
}
export type ElectionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ElectionsQuery, ElectionsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ... on User {
      id
      name
      email
      emailVerified
    }
    ... on Voter {
      id
      name
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export function useMeLazyQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>;
export const MotionDocument = gql`
    query Motion($electionId: ID!, $motionId: ID!) {
  election(electionId: $electionId) {
    id
    motion(motionId: $motionId) {
      ...MotionFragment
    }
  }
}
    ${MotionFragmentDoc}`;

/**
 * __useMotionQuery__
 *
 * To run a query within a Vue component, call `useMotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMotionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMotionQuery({
 *   electionId: // value for 'electionId'
 *   motionId: // value for 'motionId'
 * });
 */
export function useMotionQuery(variables: MotionQueryVariables | VueCompositionApi.Ref<MotionQueryVariables> | ReactiveFunction<MotionQueryVariables>, options: VueApolloComposable.UseQueryOptions<MotionQuery, MotionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MotionQuery, MotionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MotionQuery, MotionQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MotionQuery, MotionQueryVariables>(MotionDocument, variables, options);
}
export function useMotionLazyQuery(variables: MotionQueryVariables | VueCompositionApi.Ref<MotionQueryVariables> | ReactiveFunction<MotionQueryVariables>, options: VueApolloComposable.UseQueryOptions<MotionQuery, MotionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MotionQuery, MotionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MotionQuery, MotionQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MotionQuery, MotionQueryVariables>(MotionDocument, variables, options);
}
export type MotionQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MotionQuery, MotionQueryVariables>;
export const VoterElectionDocument = gql`
    query VoterElection($electionId: ID!, $motionStatus: MotionStatus, $withIcon: Boolean!) {
  election(electionId: $electionId) {
    id
    name
    status
    icon @include(if: $withIcon)
    openedAt
    privateVotes
    motions(status: $motionStatus) {
      ...VoterMotionFragment
    }
  }
}
    ${VoterMotionFragmentDoc}`;

/**
 * __useVoterElectionQuery__
 *
 * To run a query within a Vue component, call `useVoterElectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVoterElectionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVoterElectionQuery({
 *   electionId: // value for 'electionId'
 *   motionStatus: // value for 'motionStatus'
 *   withIcon: // value for 'withIcon'
 * });
 */
export function useVoterElectionQuery(variables: VoterElectionQueryVariables | VueCompositionApi.Ref<VoterElectionQueryVariables> | ReactiveFunction<VoterElectionQueryVariables>, options: VueApolloComposable.UseQueryOptions<VoterElectionQuery, VoterElectionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VoterElectionQuery, VoterElectionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VoterElectionQuery, VoterElectionQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<VoterElectionQuery, VoterElectionQueryVariables>(VoterElectionDocument, variables, options);
}
export function useVoterElectionLazyQuery(variables: VoterElectionQueryVariables | VueCompositionApi.Ref<VoterElectionQueryVariables> | ReactiveFunction<VoterElectionQueryVariables>, options: VueApolloComposable.UseQueryOptions<VoterElectionQuery, VoterElectionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VoterElectionQuery, VoterElectionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VoterElectionQuery, VoterElectionQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<VoterElectionQuery, VoterElectionQueryVariables>(VoterElectionDocument, variables, options);
}
export type VoterElectionQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<VoterElectionQuery, VoterElectionQueryVariables>;