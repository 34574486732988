<template>
  <div v-if="cookie.granted.value === null" class="fixed bottom-0 right-0 left-0 border-t bg-white border-gray-300 py-4 z-10000">
    <div class="container mx-auto px-2">
      <h2>Cookies</h2>
      <p>
        We would like to use cookies to collect anonymous aggregated usage
        statistics to know what features to improve.
        Read more in our <router-link to="/privacy">
          privacy policy
        </router-link>.
      </p>
      <div class="flex gap-4 flex-wrap mt-2">
        <text-button
          class="ml-0"
          @click="cookie.grant()"
        >
          I accept cookies
        </text-button>
        <text-button
          @click="cookie.deny()"
        >
          I deny cookies
        </text-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useConsentCookie from '../hooks/useCookieConsent'

import { TextButton } from '@ropescore/components'

const cookie = useConsentCookie()
</script>
