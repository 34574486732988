<template>
  <footer class="w-full bg-gray-100 pin-b flex items-center">
    <div class="container mx-auto flex flex-row justify-between p-2">
      <div>
        &copy;
        <a href="https://swantzter.se" target="_blank" rel="noopener">Swantzter</a>
        2021
      </div>
      <div>
        <router-link to="/privacy">
          Privacy
        </router-link>
      </div>
    </div>
  </footer>
</template>
