<template>
  <div
    class="grid min-h-[100vh] w-full"
    :class="{ 'grid-rows-[4.5rem,auto,4rem]': !route.meta.fullscreen, 'grid-rows-1': route.meta.fullscreen }"
  >
    <nav-header v-if="!route.meta.fullscreen" />
    <div>
      <router-view />
    </div>
    <app-footer v-if="!route.meta.fullscreen" />
  </div>
  <cookie-consent v-if="!route.meta.fullscreen" />
  <notification-cards />
</template>

<script setup lang="ts">
import NavHeader from './components/NavHeader.vue'
import CookieConsent from './components/CookieConsent.vue'
import AppFooter from './components/AppFooter.vue'
import NotificationCards from './components/NotificationCards.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
</script>
