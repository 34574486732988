import { ref, computed } from 'vue'
import { getAnalytics, setUserId } from '@firebase/analytics'
import { getAuth, type Unsubscribe, type User } from '@firebase/auth'
import { setUser } from '@sentry/browser'
import { apolloClient } from '../apollo'
import { useMeQuery } from '../graphql/generated/graphql'
import { useRoute, useRouter } from 'vue-router'

const analytics = getAnalytics()
const auth = getAuth()
const firebaseUser = ref<User | null>()
const loading = ref(true)
let off: Unsubscribe

export default function useAuth () {
  const userQuery = useMeQuery({ fetchPolicy: 'cache-and-network' })
  const user = computed(() => userQuery.result.value?.me ?? null)

  const route = useRoute()
  const router = useRouter()

  if (!off) {
    off = auth.onAuthStateChanged(user => {
      // set the ref to get the firebase user
      firebaseUser.value = user
      // refetch the user document from the db
      apolloClient.resetStore()
      // set the user ID for analytics
      setUserId(analytics, user?.uid ?? '')
      // set the user id for error reporting
      setUser(user ? { id: user.uid } : null)
      loading.value = false
      if (route.meta.requiresUserAuth) {
        router.replace({
          path: '/auth',
          query: {
            'return-to': encodeURIComponent(route.fullPath)
          }
        })
      }
    })
  }
  return {
    user,
    firebaseUser,
    loading
  }
}
