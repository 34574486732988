import { useSessionStorage } from '@vueuse/core'
import { toRef, watch } from 'vue'

const voterPins = useSessionStorage<Record<string, string>>('agm-voter-pins', {})

export function useVoterPins () {
  return voterPins
}

export function useVoterPin (voterId: string) {
  const pin = toRef(voterPins.value, voterId)

  watch(pin, newPin => {
    console.log('hi')
    voterPins.value[voterId] = newPin
  })

  return pin
}
