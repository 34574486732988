import { getAuth } from '@firebase/auth'
import { getAnalytics, logEvent } from '@firebase/analytics'
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'

const analytics = getAnalytics()
const auth = getAuth()

export const routes: RouteRecordRaw[] = [
  { name: 'home', path: '/', component: () => import('./views/Home.vue') },

  { name: 'elections', path: '/elections', component: () => import('./views/Elections.vue'), meta: { requiresUserAuth: true } },
  { name: 'election', path: '/elections/:electionId', component: () => import('./views/Election.vue'), meta: { requiresUserAuth: true } },
  { name: 'election-present', path: '/elections/:electionId/present', component: () => import('./views/ElectionPresent.vue'), meta: { requiresUserAuth: true, fullscreen: true } },

  { name: 'vote', path: '/elections/:electionId/vote', component: () => import('./views/Vote.vue') },
  { name: 'vote-index', path: '/vote', component: () => import('./views/VoteIndex.vue') },

  { name: 'auth', path: '/auth', component: () => import('./views/Auth.vue') },
  { name: 'privacy', path: '/privacy', component: () => import('./views/Privacy.vue') },

  { name: 'not_found', path: '/:catchAll(.*)*', component: () => import('./views/404.vue') }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to) => {
  if (!to.meta.requiresUserAuth) return true

  return new Promise(resolve => {
    const off = auth.onAuthStateChanged(user => {
      off()
      if (user) resolve(true)
      else {
        resolve({
          path: '/auth',
          query: {
            'return-to': encodeURIComponent(to.fullPath)
          },
          replace: true
        })
      }
    })
  })
})

router.afterEach((to) => {
  logEvent(analytics, 'screen_view', {
    firebase_screen: to.name?.toString() ?? '',
    firebase_screen_class: undefined
  })
})

export default router
