import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { getAuth } from '@firebase/auth'
import { useVoterPins } from './hooks/useVoterPins'
import useNotifications from './hooks/useNotifications'

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URL,
  includeExtensions: true
})

const voterPins = useVoterPins()

const authLink = setContext(async (_, { headers }) => {
  const voterId = new URLSearchParams(window.location.search).get('voter-id')
  if (voterId) {
    const auth = btoa(`${voterId}:${voterPins.value[voterId] ?? ''}`)
    return {
      headers: {
        ...headers,
        authorization: `Basic ${auth}`
      }
    }
  } else {
    const token = await getAuth().currentUser?.getIdToken()
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  }
})

const { push: pushError } = useNotifications()
const errorLink = onError(({ graphQLErrors, networkError }) => {
  const voterId = new URLSearchParams(window.location.search).get('voter-id')
  if (voterId && graphQLErrors && graphQLErrors.find(err => err.extensions.failType === 'VOTER_AUTH_FAIL')) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete voterPins.value[voterId]
    pushError({
      message: 'Authentication Error: Your Voter PIN might be wrong, or this voter/election doesn\'t exist',
      type: 'server',
      code: 'VOTER_AUTH_FAIL'
    })
    return
  }

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      console.log({ ...err })
      pushError({
        message: err.message,
        type: 'server',
        code: typeof err.extensions.code === 'string' ? err.extensions.code : undefined
      })
    }
  }
  if (networkError) {
    pushError({
      message: networkError.message,
      type: 'network'
    })
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Motion: {
      fields: {
        excludedVoters: {
          merge: false
        }
      }
    },
    Vote: {
      fields: {
        history: {
          merge: false
        }
      }
    },
    Election: {
      fields: {
        motions: {
          merge: false
        },
        admins: {
          merge: false
        },
        voters: {
          merge: false
        }
      }
    }
  }
})

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache
})
