import { initSentry } from './config'
import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { apolloClient } from './apollo'

import router from './routes'
import App from './App.vue'
import 'virtual:windi.css'
import '../node_modules/@ropescore/components/dist/style.css'

export const app = createApp(App)

initSentry({ app, router })

app
  .provide(DefaultApolloClient, apolloClient)
  .use(createHead())
  .use(router)
  .mount('#app')
