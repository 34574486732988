<template>
  <header class="border-b border-gray-200 bg-white">
    <div class="container mx-auto p-2 flex items-center h-full justify-between">
      <router-link class="flex title-font font-medium items-center text-gray-900" to="/">
        <span class="text-2xl font-semibold">AGM Vote</span>
      </router-link>

      <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center">
        <router-link class="mr-5 hover:text-gray-900" to="/elections">
          Elections
        </router-link>
        <router-link class="mr-5 hover:text-gray-900" to="/vote">
          Vote
        </router-link>
        <template v-if="!firebaseUser">
          <router-link class="mr-5 hover:text-gray-900" to="/auth">
            Log in
          </router-link>
          <router-link class="mr-5 hover:text-gray-900" to="/auth">
            Register
          </router-link>
        </template>
        <a v-else class="mr-5 hover:text-gray-900 cursor-pointer" @click="auth.signOut()">
          Log Out
        </a>
      </nav>
    </div>
  </header>
</template>

<script setup lang="ts">
import useAuth from '../hooks/useAuth'
import { getAuth } from '@firebase/auth'

const { firebaseUser } = useAuth()
const auth = getAuth()
</script>
